/*
  Project: ASICS Digital
  Author: piotr@kulpinski.pl
 */

import ui from './utils/ui';
import panels from './utils/panels';

// Panels
panels.init();

// UI
ui.init();
