import throttle from 'lodash.throttle';

const Events = {
  init() {
    window.onload = () => setTimeout(this.handleLoad(), 0);
    window.onresize = throttle(this.handleResize, 200);
    window.onscroll = throttle(this.handleScroll, 50);
  },

  handleLoad() {
    Events.calculatePlaceholderHeight();
  },

  handleResize() {
    Events.calculatePlaceholderHeight();
  },

  handleScroll() {},

  /* Private functions */
  calculatePlaceholderHeight() {
    const root = document.documentElement;
    const objects = Array.from(document.querySelectorAll('[data-placehold-height]'));

    objects.forEach((object) => {
      const name = object.dataset.placeholdHeight;
      root.style.setProperty(`--${name}-height`, `${object.clientHeight}px`);
    });
  },
};

export default Events;
