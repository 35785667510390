import { scrollBy, scrollIntoView } from 'seamless-scroll-polyfill';

const Scroller = {
  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-scroll]'));

    if (triggers.length) {
      triggers.forEach((trigger) => trigger.addEventListener('click', this.handleScroll.bind(this)));
    }
  },

  handleScroll(ev) {
    const trigger = ev.currentTarget;
    let target = trigger.dataset.scroll || trigger.getAttribute('href');

    if (target && target.length > 1) {
      // Remove everything before the "#"
      target = target.substring(target.indexOf('#'));

      // Scroll to target
      if (this.scrollTo(target)) {
        // Prevent page jumping
        ev.preventDefault();
      }
    }
  },

  scrollTo(target, offset = 0) {
    const element = target instanceof Element ? target : document.querySelector(target);

    if (element) {
      window.globals.autoScrolling = true;

      scrollIntoView(element, {
        behavior: 'smooth',
      });

      if (offset !== 0) {
        scrollBy(window, {
          behavior: 'smooth',
          top: offset,
        });
      }

      // Close panels
      document.dispatchEvent(new CustomEvent('dropdown:close'));
      document.dispatchEvent(new CustomEvent('panels:close'));

      setTimeout(() => {
        window.globals.autoScrolling = false;
      }, 1000);
    }

    return element;
  },
};

export default Scroller;
