import Flickity from 'flickity';
import normalizeWheel from 'normalize-wheel';

const Carousel = {
  defaults: {
    cellAlign: 'center',
    freeScroll: true,
    draggable: true,
    contain: true,
    pageDots: false,
    prevNextButtons: false,
  },

  init(element = document) {
    const carousels = Array.from(element.querySelectorAll('[data-carousel]'));

    /* eslint no-underscore-dangle: 0 */
    /* eslint func-names: 'off' */
    Flickity.prototype._createResizeClass = function () {
      this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    const { resize } = Flickity.prototype;
    Flickity.prototype.resize = function () {
      this.element.classList.remove('flickity-resize');
      resize.call(this);
      this.element.classList.add('flickity-resize');
    };

    // Defer initializing the carousel to make sure the fonts and images are loaded
    window.addEventListener('load', () => {
      carousels.forEach((carousel) => {
        this.initCarousel(carousel, carousel.dataset.carousel ? JSON.parse(carousel.dataset.carousel) : {});
      });
    });
  },

  initCarousel(carousel, options = {}) {
    if (carousel.childElementCount > 1) {
      const flickity = new Flickity(carousel, {
        ...Carousel.defaults,
        ...options,
      });

      if (!Carousel.isTouchDevice() && flickity.options.freeScroll) {
        flickity.element.addEventListener('wheel', (ev) => {
          const wheelNormalized = normalizeWheel(ev);
          flickity.applyForce(-wheelNormalized.pixelY / 10);
          flickity.startAnimation();
          flickity.dragEnd();

          ev.preventDefault();
        });
      }

      if (carousel.dataset.carouselDots) {
        const dots = document.querySelector(carousel.dataset.carouselDots);
        const dotsItems = Array.from(dots.children);

        if (dotsItems.length) {
          flickity.on('select', (index) => {
            dotsItems.forEach((dot) => dot.classList.remove('is-active'));
            dotsItems[index].classList.add('is-active');
          });

          dotsItems.forEach((dot, index) => {
            dot.addEventListener('click', (ev) => {
              ev.preventDefault();
              flickity.select(index);
            });
          });
        }
      }

      if (carousel.dataset.carouselPrevious) {
        const previous = document.querySelector(carousel.dataset.carouselPrevious);

        if (previous) {
          previous.addEventListener('click', (ev) => {
            ev.preventDefault();
            flickity.previous(true);
          });
        }
      }

      if (carousel.dataset.carouselNext) {
        const next = document.querySelector(carousel.dataset.carouselNext);

        if (next) {
          next.addEventListener('click', (ev) => {
            ev.preventDefault();
            flickity.next(true);
          });
        }
      }
    }
  },

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  },
};

export default Carousel;
