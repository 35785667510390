import axios from 'axios';
import UI from '../utils/ui';

const Popup = {
  clearing: false,
  autoPopupDisplayed: false,

  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-popup]'));

    if (triggers.length) {
      triggers.forEach((trigger) => {
        trigger.removeEventListener('click', this.handlePopup);
        trigger.addEventListener('click', this.handlePopup);
      });
    }

    document.addEventListener('panels:close', this.clearPopup.bind(this));
    document.addEventListener('popup:close', this.clearPopup.bind(this));
  },

  handlePopup(ev, instance = null) {
    ev.preventDefault();

    const trigger = ev.currentTarget;
    const target = instance || trigger.dataset.popup;

    if (Popup.clearing) {
      return;
    }

    if (trigger && target) {
      // Show loader
      document.body.classList.add('is-loading');

      if (target === 'remote') {
        const targetURL = new URL(ev.currentTarget.href);

        // Set remote param
        targetURL.searchParams.set('remote', true);

        axios.get(targetURL).then((response) => Popup.callbacks.insertTemplate(response.data));
      } else {
        const element = instance ? target : document.querySelector(target);

        if (element) {
          Popup.callbacks.insertTemplate(element.innerHTML);
        }
      }
    } else {
      Popup.clearPopup();
    }
  },

  clearPopup() {
    if (Popup.content) {
      Popup.clearing = true;

      setTimeout(() => {
        Popup.content.innerHTML = '';
        Popup.clearing = false;
      }, 500);
    }

    // Remove popup class
    document.body.classList.remove('has-popup-open');
  },

  callbacks: {
    insertTemplate(template) {
      // Set popup content
      Popup.content = document.querySelector('[data-popup-content]');

      // Insert template
      Popup.content.innerHTML = template;

      // Play videos if there are any
      Popup.callbacks.playVideo();

      // Reinit UI elements
      UI.init(this.content);

      // Add popup class
      document.body.classList.add('has-popup-open');

      // Hide loader
      document.body.classList.remove('is-loading');
    },

    playVideo(content = null) {
      const popupContent = content || Popup.content;
      const video = popupContent.querySelector('[data-popup-video]');

      if (video) {
        const player = video.querySelector('video');

        player.play();
      }

      // Set popup content layout type
      popupContent.parentNode.dataset.layout = video ? 'video' : 'content';
    },
  },
};

export default Popup;
