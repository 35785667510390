import axios from 'axios';
import get from 'lodash.get';
import carousel from './carousel';

const Actions = {
  triggers: [],

  init(element = document) {
    this.triggers = Array.from(element.querySelectorAll('[data-action]'));

    if (this.triggers.length) {
      this.triggers.forEach((trigger) => {
        const event = trigger.dataset.actionEvent;

        if (event === 'load') {
          window.addEventListener(event, (ev) => this.handleEvent(ev, trigger));
        } else {
          trigger.addEventListener(event, (ev) => this.handleEvent(ev));
        }
      });
    }
  },

  handleEvent(ev, trigger) {
    if (ev) {
      if (!trigger) {
        trigger = ev.currentTarget;
      }

      if (trigger.getAttribute('action') === '#') {
        ev.preventDefault();
        ev.stopPropagation();
      }
    }

    this.runActions(trigger, [{ data: trigger.dataset }]);
  },

  runActions(trigger, actions) {
    const requests = [];

    if (actions && actions.length) {
      // Enable loading indicator
      trigger.classList.add('is-loading');

      actions.forEach((action) => {
        const formData = new FormData(trigger.hasAttribute('action') ? trigger : document.createElement('form'));

        // Extend formData with trigger data attributes
        this.extendFormData(formData, action.data);

        // Allows to suffix action name with unique identifier
        if (formData.has('action')) {
          const actionParts = formData.get('action').split('-');

          formData.set('action', actionParts[0]);
          formData.set('salt', actionParts[1]);
        }

        // Extend formData if action was triggered on select element
        if (trigger.hasAttribute('name')) {
          const option = trigger.options[trigger.selectedIndex];

          if (option && typeof option.value !== 'undefined') {
            action.data.value = option.dataset.value;
            formData.append('value', option.value);
          }
        }

        // Assign callback function
        action.callback = action.callback || this.callbacks[formData.get('action')] || (() => {});

        // Assign new request
        if (trigger.dataset.actionUrl) {
          requests.push(axios.get(trigger.dataset.actionUrl, formData));
        } else {
          requests.push(axios.post(window.globals.ajax_url, formData));
        }
      });

      // Run concurrent action
      axios.all(requests).then(
        axios.spread((...args) => {
          setTimeout(() => {
            args.forEach((arg, index) => {
              actions[index].callback(trigger, arg.data, actions[index].data.action);
            });

            // Disable loading indicator
            trigger.classList.remove('is-loading');
          }, 250);
        }),
      );
    }
  },

  extendFormData(formData, data) {
    Object.keys(data).forEach((key) => {
      if (key === 'actionData') {
        this.extendFormData(formData, JSON.parse(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    });
  },

  insertIntoTemplate(trigger, items, templateName, mapObj = {}, callback = null) {
    const template = document.getElementById(templateName).innerHTML;

    if (items) {
      items.forEach((item) => {
        const replacer = new RegExp(Object.keys(mapObj).join('|'), 'gi');

        trigger.insertAdjacentHTML(
          'beforeend',
          template.replace(replacer, (matched) => get(item, mapObj[matched]).replace(/(<([^>]+)>)/gi, '')),
        );
      });

      if (callback) {
        setTimeout(() => {
          callback(trigger);
        }, 0);
      }
    }
  },

  callbacks: {
    load_jobs: (trigger, response) => {
      const tableBody = trigger.appendChild(document.createElement('tbody'));

      Actions.insertIntoTemplate(tableBody, response, 'template-job', {
        '{title}': 'text',
        '{location}': 'categories.location',
        '{team}': 'categories.team',
        '{url}': 'hostedUrl',
      });
    },

    load_posts: (trigger, response) => {
      Actions.insertIntoTemplate(
        trigger,
        response.items,
        'template-post',
        {
          '{link}': 'link',
          '{title}': 'title',
          '{thumbnail}': 'thumbnail',
          '{author}': 'author',
          '{description}': 'description',
        },
        carousel.initCarousel,
      );
    },
  },
};

export default Actions;
