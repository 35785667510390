import panel from '../modules/panel';
import expander from '../modules/expander';
import scroll from '../modules/scroll';
import carousel from '../modules/carousel';
import popup from '../modules/popup';
import actions from '../modules/actions';
import events from '../modules/events';

const Ui = {
  init(element) {
    panel.init(element);
    expander.init(element);
    scroll.init(element);
    carousel.init(element);
    popup.init(element);
    actions.init(element);
    events.init(element);
  },
};

export default Ui;
